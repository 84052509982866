<template>
  <form class="add main-block" @submit.prevent="expeditorSubmit">
    <div class="add__box main-block__head">
      <h1 class="add__box-title main-block__title">Информация</h1>
      <button type="submit" class="main-block__btn default-btn">Сохранить</button>
    </div>
    <ul class="add__items">
      <!--      <li class="add__item flex">-->
      <!--        <p class="add__key">Фамилия *</p>-->
      <!--        <input type="text" v-model="surname" class="add__value" placeholder="Фамилия" :disabled="editDisabled">-->
      <!--      </li>-->
      <li class="add__item flex">
        <p class="add__key">ФИО *</p>
        <input v-model="name" type="text" class="add__value" placeholder="Имя" />
      </li>
      <li class="add__item flex">
        <p class="add__key">Дата рождения *</p>
        <b-calendar
          v-show="showCalendar"
          v-model="date"
          class="add__calendar"
          :hide-header="true"
          @context="onContext(date)"
        />
        <input
          v-model="date"
          type="text"
          class="add__value"
          placeholder="Дата"
          @click="showCalendar = !showCalendar"
          @input="showCalendar = false"
        />
      </li>
      <li class="add__item flex">
        <p class="add__key">Город проживания *</p>
        <b-form-select
          v-model="city"
          class="transport-add__value main__select"
          :options="list"
          text-field="name"
          value-field="id"
        />
        <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
      </li>
      <li class="add__item flex">
        <p class="add__key">№ телефона *</p>
        <the-mask
          v-model="phone"
          type="text"
          mask="+7 (7##) ### ####"
          class="add__value add__value_aligin"
          placeholder="+7"
        />
      </li>
      <li class="add__item flex">
        <p class="add__key">Пароль</p>
        <input
          v-model="password"
          type="password"
          class="add__value add__value_aligin"
          placeholder="Пароль"
        />
      </li>
      <li class="add__item flex">
        <p class="add__key">ИИН *</p>
        <the-mask
          v-model="iin"
          type="text"
          mask="### ### ### ###"
          class="add__value add__value_aligin"
          placeholder="ИИН"
        />
      </li>
      <li class="add__item flex">
        <p class="add__key">№ уд.личности *</p>
        <input
          v-model="docNumber"
          type="number"
          class="add__value add__value_aligin"
          placeholder="№"
        />
      </li>
      <!--      <li class="add__item flex">-->
      <!--        <p class="add__key">№ водительского уд.*</p>-->
      <!--        <input type="number" v-model="docNumber" class="add__value add__value_aligin" placeholder="№">-->
      <!--      </li>-->
      <li class="add__item flex">
        <p class="add__key">Срок истечения водит.уд</p>
        <b-calendar
          v-show="showDeadline"
          v-model="deadline"
          class="add__calendar"
          :hide-header="true"
          @context="onContext(deadline)"
        />
        <input
          v-model="deadline"
          type="text"
          class="add__value add__value_aligin"
          placeholder="№"
          @click="showDeadline = !showDeadline"
          @input="showDeadline = false"
        />
      </li>
      <li class="add__item flex">
        <p class="add__key">Табельный номер </p>
        <input
          v-model="tableNumber"
          type="number"
          class="add__value add__value_aligin"
          placeholder="№"
        />
      </li>
    </ul>
    <div
      v-if="showCalendar || showDeadline"
      class="close-black"
      @click="(showCalendar = false), (showDeadline = false)"
    />
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      surname: null,
      name: null,
      lastname: null,
      date: null,
      city: null,
      phone: null,
      password: null,
      iin: null,
      docNumber: null,
      deadline: null,
      showCalendar: false,
      showDeadline: false,
      imageEdit: false,
      editDisabled: false,
      dateBefore: null,
      tableNumber: null
    };
  },
  computed: {
    ...mapState(["citiesProducts", "driversById", "imagesAdd"]),
    list() {
      if (this.citiesProducts) {
        return [
          {
            id: null,
            name: "Выберите",
            disabled: true,
          },
          ...this.citiesProducts,
        ];
      }
      return [
        {
          mark_id: null,
          name: "Выберите",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    "$route.query"() {
      if (!this.$route.query.id) {
        this.surname = "";
        this.name = "";
        this.date = "";
        this.city = "";
        this.phone = "";
        this.iin = "";
        this.docNumber = "";
        this.deadline = "";
        this.editDisabled = false;
        this.tableNumber = "";
      }
    },
    imagesAdd() {
      this.imageEdit = true;
    },
  },
  async mounted() {
    if (this.$route.query.id) {
      await this.$store.dispatch("getDriversById", {
        user_id: this.$route.query.id,
      });
      this.editDisabled = "disabled";
    }
    if (this.driversById && this.$route.query.id) {
      this.surname = this.driversById.surname;
      this.name = this.driversById.name;
      this.date = this.driversById.date;
      this.city = this.driversById.city;
      this.phone =
        this.driversById.phone?.length === 11
          ? this.driversById.phone
          : "7" + this.driversById.phone;
      this.iin = this.driversById.iin;
      this.docNumber = this.driversById.documents?.doc_number;
      this.tableNumber = this.driversById.table_number;
      this.deadline = this.driversById.documents?.deadline.split(" ")[0];
    }
  },
  async created() {
    await this.$store.dispatch("getCitiesProducts");
  },
  methods: {
    expeditorSubmit() {
      // if (!this.date) {
      //   this.$toast.warning("Введите день рождения")
      //   return
      // }
      if (this.$route.query.id) {
        this.driverEdit();
      } else {
        this.driverAdd();
      }
    },
    async driverAdd() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("surname", this.surname);
      formData.append("date", this.date);
      formData.append("iin", this.iin);
      formData.append("phone", "7" + this.phone);
      formData.append("password", this.password);
      formData.append("city_id", this.city);
      formData.append("role", 'wms');
      if (this.imagesAdd && this.imagesAdd.length) {
        formData.append("documents[0]", this.imagesAdd[0]);
      }
      formData.append("deadline", this.deadline);
      formData.append("doc_number", this.docNumber);
      formData.append('table_number', this.tableNumber);
      await this.$api
        .post("/web/register-user", formData)
        .then(() => {
          this.$store.dispatch("getDriversList");
          this.$router.push("/database/drivers");
          this.$toast.success("Успешно добавлен");
        })
        .catch((e) => {
          this.$store.commit("setToastData", {
            text: e.response.data.message,
            position: "error",
          });
          this.$toast.error(<toast-block />);
          if (
            e.message.split(" ").reverse()[0] === 500 ||
            e.message.split(" ").reverse()[0] === 401
          ) {
            this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
          }
        });
    },
    async driverEdit() {
      const formData = new FormData();
      formData.append("user_id", this.$route.query.id);
      formData.append("name", this.name);
      formData.append("surname", this.surname);
      formData.append("date", this.date);
      formData.append("iin", this.iin);
      formData.append("phone", "7" + this.phone);
      formData.append("password", this.password);
      formData.append("city_id", this.city);
      formData.append("doc_number", this.docNumber);
      formData.append("deadline", this.deadline);
      formData.append('table_number', this.tableNumber);
      formData.append("role", 'wms');
      if (this.imagesAdd && this.imagesAdd.length && this.imageEdit) {
        formData.append(`documents[${this.imagesAdd.length + 2}]`, this.imagesAdd[0]);
      }
      await this.$api
        .post("/web/user-update", formData)
        .then(() => {
          this.$store.dispatch("getDriversList");
          this.$router.push("/database/drivers");
          this.$toast.success("Успешно изменен");
        })
        .catch((e) => {
          this.$store.commit("setToastData", {
            text: e.response.data.message,
            position: "error",
          });
          this.$toast.error(<toast-block />);
          if (
            e.message.split(" ").reverse()[0] === 500 ||
            e.message.split(" ").reverse()[0] === 401
          ) {
            this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
          }
        });
    },
    onContext(e) {
      if (this.dateBefore !== e) {
        this.showCalendar = false;
        this.showDeadline = false;
      }
      this.dateBefore = e;
    },
  },
};
</script>

<style scoped>
.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}

.transport-add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
</style>
